import * as React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout/layout";
import {useTranslation} from "react-i18next";
import {cover, coverHidden} from "./index.module.scss";

const NotFoundPage = () => {
    const {t} = useTranslation();
    return (
        <Layout pageTitle={t('pages.error.title')}>
            <div className={cover}/>
            <div className={coverHidden}/>

            <div className="container d-flex align-items-center justify-content-center">
                <div className="topOfCover">
                    <div className="text-box">
                    <p>{t('pages.error.title')}</p>
                    <p>{t('pages.error.info')} <Link to="/">{t('pages.error.home')}?</Link></p>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
